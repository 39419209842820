import React from "react";
import { Col } from "react-bootstrap";
import "./About.css";

function About() {
  return (
    <div className="comingSoon">
      <h1>Coming Soon.</h1>
    </div>
  );
}

export default About;
