import React from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "react-bootstrap";
import config from "../../data/SiteConfig";
import About from "../components/About/About";
import Layout from "../layout";
import COLORS from '../styles/COLORS';


function ZoomifyPage() {
  return (
    <Layout location="artworks" title="ZoomfiyTest">
      <Helmet>
        <script
          src="../zoomify/ZoomifyImageViewerPro-min.js"
          type="text/javascript"
        />
        {/* <script type="text/javascript">
        $(document).ready(function() {
          Z.showImage("myZoomifyContainer", "../zoomify/Images/Elkiva/16ships",
          "zBaseZIndex=-1&zNavigatorTop=65&zNavigatorLeft=5&zInitialZoom=50&zNavigatorRectangleColor=#000000")
        }
        </script> */}
      </Helmet>
      <Row className="py-3" style={{ background: COLORS.darks.raised.r100 }}>
        <div className="about-container">
          <Helmet title={`About | ${config.siteTitle}`} />
          <About />
          <div id="myZoomifyContainer" style={{ border: "solid red" }}></div>
        </div>
      </Row>
    </Layout>
  );
}

export default ZoomifyPage;
